<template>
  <div class="drivers-mb-footer">
    <div class="drivers-mb-footer__inner">
      <ul class="drivers-mb-footer__menu" role="menubar">
        <li
          v-for="(menuItem, i) in menuItems"
          :key="i"
          role="menuitem"
          :class="[
            'drivers-mb-footer__menu-item',
            'is-route',
            { 'is-route': checkIsRoute(menuItem) },
          ]"
        >
          <div :class="`menu-box el_${i}`">
            <router-link
              exact
              :to="{ name: menuItem.name }"
              class="drivers-mb-footer__menu-link"
              :class="computedRoute(menuItem.name)"
            >
              <Icon
                :name="menuItem.icon"
                width="41px"
                height="40px"
                icon-color="#000000"
              />
              {{ menuItem.title }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mobileDriverMenu } from '@/constants/navigaiton'
import Icon from '@/UI/icon/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeMenuItemId: null,
      menuItems: mobileDriverMenu,
    }
  },
  computed: {
    ...mapGetters('responses', ['totalCount', 'currentCount']),
  },
  methods: {
    computedRoute(name) {
      if (name === this.$route.name) {
        return 'active'
      }
    },
    checkIsRoute(c) {
      const route = this.$route.fullPath

      return c.path && c.path === route
    },
    onItemClick(item) {
      if (this.activeMenuItemId && this.activeMenuItemId === item.id) {
        this.activeMenuItemId = null

        return
      }
      this.activeMenuItemId = item.id
    },
  },
}
</script>
<style lang="sass">
$backColor: #A5D3EB
$backActiveColor: #0C97C7
$textColor: #fff
$activeColor: rgb(255, 255, 255)
.router-link-active
  background: $backActiveColor
  font-weight: bold!important
.active
  background: $backActiveColor!important

.drivers-mb-footer
  background-color: $backColor
  color: #333
  height: 50px
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 999
  font-size: 12px
  @media(min-width: 360px)
    font-size: 16px
    height: 70px
  &__inner
    position: relative
    width: 100%
    height: 100%
  &__menu
    background-color: $backColor
    list-style: none
    padding: 0
    margin: 0
    width: 100%
    height: 100%
    display: flex
  &__menu-link
    fill: red!important
    color: inherit
    text-decoration: none
    text-align: center
    height: 100%
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size: 13px
    svg
      fill: white
      margin-bottom: 4px

  &__menu-item
    background-color: $backColor
    flex-grow: 1
    position: relative
    width: 50%

    &.is-route
      font-family: Roboto
      font-style: normal
      font-weight: 500
      font-size: 14px!important
      line-height: 22px
      text-align: center
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #FFFFFF!important
      text-transform: uppercase
      text-decoration: none
      >.menu-box
        button, a
          font-weight: normal
    >.menu-box
      position: relative
      width: 100%
      height: 100%
      &:before
        content: ''
        position: absolute
      &.el_0:before
        top: 10px
        left: calc(50% - 9px)
        width: 17px
        height: 18px
        background-size: contain
        fill: white
      &.el_1:before
        top: 12px
        left: calc(50% - 18px)
        width: 36px
        height: 17px
        background-size: contain
        fill: white

      >.drivers-mb-footer
        &__menu-btn, &__menu-link
          padding: 15px 0 5px
          margin: 0 auto
  &__submenu
    box-shadow: 0px 0px 5px #ccc
  &__submenu-item
    color: $textColor
    &.is-route
      color: $textColor
      text-decoration: none
      button, a
        font-weight: bold
  &__divider
    &.is-2
      background-color: #ccc
</style>
