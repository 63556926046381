var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drivers-mb-footer" }, [
    _c("div", { staticClass: "drivers-mb-footer__inner" }, [
      _c(
        "ul",
        { staticClass: "drivers-mb-footer__menu", attrs: { role: "menubar" } },
        _vm._l(_vm.menuItems, function (menuItem, i) {
          return _c(
            "li",
            {
              key: i,
              class: [
                "drivers-mb-footer__menu-item",
                "is-route",
                { "is-route": _vm.checkIsRoute(menuItem) },
              ],
              attrs: { role: "menuitem" },
            },
            [
              _c(
                "div",
                { class: `menu-box el_${i}` },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "drivers-mb-footer__menu-link",
                      class: _vm.computedRoute(menuItem.name),
                      attrs: { exact: "", to: { name: menuItem.name } },
                    },
                    [
                      _c("Icon", {
                        attrs: {
                          name: menuItem.icon,
                          width: "41px",
                          height: "40px",
                          "icon-color": "#000000",
                        },
                      }),
                      _vm._v(" " + _vm._s(menuItem.title) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }